import React from "react";
import { css } from "@emotion/core";
import theme from "../constants/theme";

const AnchorTarget = React.forwardRef((props, ref) => {
  return (
    <div
      ref={ref}
      css={css`
        position: relative;
        height: 0;
        top: -${theme.nav.height};
      `}
      {...props}
    />
  );
});

export default AnchorTarget;
