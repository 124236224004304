import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
// import styled from "@emotion/styled";
import { css } from '@emotion/core'
import { ThemeProvider } from 'emotion-theming'
import styles from '../constants/theme'

import SEO from '../components/seo'
import Link from '../components/Link'
import Layout from '../components/layout'
import Text from '../components/Text'
import H1 from '../components/H1'
import HeroBG from '../components/HeroBG'
import MainContentWrap from '../components/MainContentWrap'
import ContentSection from '../components/ContentSection'
import CustomContainer from '../components/CustomContainer'
import AnchorTarget from '../components/AnchorTarget'
import GatsbyImage from 'gatsby-image'

import Constitution2024 from '../images/OCMA-Constitution-May-2024.pdf'

const Executive = ({ imageObj, name, title, email, ...rest }) => {
  return (
    <div
      className="col-lg-6"
      css={(theme) => css`
        background-color: ${theme.bg};
        &:nth-of-type(4n + 3),
        &:nth-of-type(4n + 4) {
          .executiveImage {
            order: 1;
          }

          .executiveInfo {
            order: 2;

            &:before {
              transform: scaleY(0.5) translate(-50%, -50%) rotate(45deg);
              right: unset;
              left: 0;
            }
          }
        }

        ${styles.mq[2]} {
          &:nth-of-type(odd) {
            .executiveImage {
              order: 2;
            }

            .executiveInfo {
              order: 1;
              &:before {
                transform: scaleY(0.5) translate(50%, -50%) rotate(45deg);
                right: 0;
                left: unset;
              }
            }
          }
          &:nth-of-type(even) {
            .executiveImage {
              order: 1;
            }

            .executiveInfo {
              order: 2;
              &:before {
                transform: scaleY(0.5) translate(-50%, -50%) rotate(45deg);
                right: unset;
                left: 0;
              }
            }
          }
        }

        ${styles.mq[0]} {
          &:nth-of-type(odd),
          &:nth-of-type(even) {
            .executiveImage {
              order: 1;
            }

            .executiveInfo {
              order: 2;

              padding: ${styles.margin.small};

              &:before {
                right: unset;
                left: 50%;
                top: 0;
                transform: scaleX(0.5) translate(-50%, -50%) rotate(45deg);
              }
            }
          }
        }
      `}
    >
      <div className="row" {...rest}>
        <div
          className="executiveInfo col-sm-6 "
          css={(theme) => css`
            padding: 0 ${styles.margin.small};
            display: flex;
            flex-direction: column;
            position: relative;

            &:before {
              content: '';
              position: absolute;
              background-color: ${theme.bg};
              width: 32px;
              height: 32px;
              transform: scaleY(0.5) translate(50%, -50%) rotate(45deg);
              top: 50%;
              transform-origin: 50%;
              right: 0;
              display: inline-block;
              z-index: 3;
            }
          `}
        >
          <div
            css={css`
              flex: 1;
              display: flex;
              align-items: center;
            `}
          >
            <Text h3>{name}</Text>
          </div>

          <Text
            pFeat
            css={css`
              margin-bottom: 0;
            `}
          >
            {title}
          </Text>
          <Link to={`mailto:${email}`}>
            <Text
              css={css`
                font-size: 13px;
              `}
            >
              {email}
            </Text>
          </Link>
        </div>
        <div className="executiveImage col-sm-6 p-0">
          <GatsbyImage fluid={imageObj} />
        </div>
      </div>
    </div>
  )
}

const CollegesGroup = ({ region, ...rest }) => {
  return (
    <div
      css={css`
        margin-bottom: ${styles.margin.mid};
      `}
      {...rest}
    >
      <div
        css={css`
          border-bottom: 1px solid ${styles.colors.blueLight};
          margin-bottom: ${styles.margin.small};
        `}
      >
        <Text
          h3
          css={css`
            position: relative;
            display: inline-block;
            padding: 0 8px;
            margin: 0 0 2px;

            &:before {
              content: '';
              width: 100%;
              height: 5px;
              background-color: currentColor;
              position: absolute;
              bottom: -2px;
              left: 0;
            }
          `}
        >
          {region.region}
        </Text>
      </div>
      <div className="row">
        {region.colleges.map((college, i) => (
          <div
            className="d-flex col-6 col-sm-4 col-md-3 justify-content-center align-items-center"
            key={`college_${i}_${Math.random()}`}
          >
            <Link
              to={college.link}
              css={css`
                width: 100%;
                margin-bottom: ${styles.margin.mid};
              `}
            >
              {NonStretchedImage(college.image.localFile.childImageSharp)}
            </Link>
          </div>
        ))}
      </div>
    </div>
  )
}

const NonStretchedImage = (props) => {
  let normalizedProps = props
  if (props.fluid && props.fluid.presentationWidth) {
    normalizedProps = {
      ...props,
      style: {
        ...(props.style || {}),
        maxWidth: props.fluid.presentationWidth,
        margin: '0 auto', // Used to center the image
      },
    }
  }

  return <GatsbyImage {...normalizedProps} />
}

export default function About() {
  const pageQuery = useStaticQuery(graphql`
    query aboutPageQuery {
      lisaKosterImage: file(relativePath: { eq: "Mallory.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      about: wordpressPage(title: { eq: "About Us" }) {
        title
        acf {
          hero_image {
            localFile {
              childImageSharp {
                fluid(quality: 90, maxWidth: 1920, toFormat: JPG) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          team_members {
            name
            title
            email
            member_image {
              localFile {
                childImageSharp {
                  fluid(
                    maxWidth: 285
                    maxHeight: 285
                    toFormat: JPG
                    quality: 90
                  ) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
          history
          aims {
            title
            description
            image {
              localFile {
                childImageSharp {
                  fixed(height: 46) {
                    ...GatsbyImageSharpFixed
                  }
                }
              }
            }
          }
        }
      }
      colleges: wordpressPage(title: { eq: "Ontario Colleges" }) {
        acf {
          heading
          regions {
            region
            colleges {
              link
              image {
                localFile {
                  childImageSharp {
                    fluid(quality: 90, maxHeight: 160) {
                      ...GatsbyImageSharpFluid
                      presentationWidth
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const aboutACF = pageQuery.about.acf
  const collegesACF = pageQuery.colleges.acf

  const teamMembersArray = [
    ...aboutACF.team_members,
    {
      email: ' ',
      member_image: {
        localFile: {
          childImageSharp: {
            fluid: pageQuery.lisaKosterImage.childImageSharp.fluid,
          },
        },
      },
      name: 'Mallory Andrews',
      title: 'Executive Member',
    },
   // {
   //   email: 'ekox@fanshawec.ca',
   //   member_image: {
   //     localFile: {
  //        childImageSharp: {
  //          fluid: pageQuery.afshinAzariImage.childImageSharp.fluid,
  //        },
 //       },
  //    },
  //    name: 'Erin Kox',
  //    title: ' ',
 //   },
   // {
   //   email: 'monica.wong@senecacollege.ca',
   //   member_image: {
    //    localFile: {
   //       childImageSharp: {
    //        fluid: pageQuery.monicaWongImage.childImageSharp.fluid,
    //      },
    //    },
   //   },
   //   name: 'Monica Wong',
   //   title: 'Executive Member',
  //  },
  ]

  return (
    <Layout>
      <SEO title={pageQuery.about.title} keywords={['']} />
      <HeroBG imageURL={aboutACF.hero_image.localFile.childImageSharp.fluid} />
      <div className="container text-center">
        <ThemeProvider theme={styles.palette.blue}>
          <H1>{pageQuery.about.title}</H1>
        </ThemeProvider>
      </div>
      <MainContentWrap>
        <CustomContainer>
          {/* <ContentSection>
            <AnchorTarget id="history" />
            <Text h1>History</Text>
            <Text>
              Many of us work in isolation and we would welcome opportunities to meet with college faculty across the province. Our
              association is a grass roots organization which attempts to improve the mathematics teaching and learning environment at
              Ontario Colleges of Applied Arts and Technologies (CAAT).
            </Text>
          </ContentSection> */}
          <ContentSection>
            <Text
              dangerouslySetInnerHTML={{ __html: aboutACF.history }}
              className="text-center"
            />
          </ContentSection>
          <ContentSection>
            <AnchorTarget id="purpose" />

            <Text h1>Our Purpose</Text>
            <Text
              h2
              css={css`
                color: ${styles.colors.red};
                margin-bottom: ${styles.margin.mid};
              `}
            >
              {aboutACF.aims_title}
            </Text>
            <div className="row">
              {aboutACF.aims.map((aim, i) => (
                <div
                  key={`about-aim-${i}`}
                  className="col-md-6"
                  css={css`
                    margin-bottom: ${styles.margin.mid};
                  `}
                >
                  <div
                    css={css`
                      display: flex;
                      align-items: center;
                      margin-bottom: ${styles.margin.small};
                    `}
                  >
                    <GatsbyImage
                      fixed={aim.image.localFile.childImageSharp.fixed}
                      css={css`
                        margin-right: ${styles.margin.small};
                      `}
                    />
                    <Text
                      h4
                      css={css`
                        color: ${styles.colors.red};
                      `}
                    >
                      {aim.title}
                    </Text>
                  </div>
                  <Text
                    css={css`
                      padding-left: ${40 + parseInt(styles.margin.small)}px;
                    `}
                  >
                    {aim.description}
                  </Text>
                </div>
              ))}
            </div>
            <CustomContainer
            className="text-center"
            css={css`
              display: flex;
              justify-content: center;
              align-items: center;
            `}
          >
             <Link
  to={Constitution2024}
  target="_blank"
  css={css`
    background-color: rgb(245, 107, 98); /* Specific red color using hex code */
    padding: 10px;
    font-size: 24px;
    font-weight: 700;
    text-align: center;
    display: inline-block;
    border-radius: 5px;
    text-decoration: none;
    color: white; /* Change text color to white */
    margin-top: none;
    &:hover {
    background-color: rgb(245, 107, 98); /* Ensure hover has no effect */
    }
  `}
>
              Read the OCMA Constitution
            </Link>
          </CustomContainer>
    
          </ContentSection>
        </CustomContainer>
        <div className="row">
          <ContentSection>
            <AnchorTarget id="executives" />
            <Text
              h1
              className="text-center"
              css={css`
                margin-bottom: ${styles.margin.mid};
              `}
            >
              Executives
            </Text>
            <ThemeProvider theme={styles.palette.blue}>
              <div className="col">
                <div className="row">
                  {aboutACF.team_members &&
                    teamMembersArray.map((member, i) => (
                      <Executive
                        key={`teamMember_${i}`}
                        imageObj={
                          member.member_image.localFile.childImageSharp.fluid
                        }
                        name={member.name}
                        title={member.title}
                        email={member.email}
                      />
                    ))}
                </div>
              </div>
            </ThemeProvider>
          </ContentSection>
        </div>

        <ContentSection>
          <AnchorTarget id="colleges" />
          <CustomContainer>
            <Text
              h1
              className="text-center"
              css={css`
                margin-bottom: ${styles.margin.mid};
              `}
            >
              {collegesACF.heading}
            </Text>
            {collegesACF.regions.map((region, i) => (
              <CollegesGroup region={region} key={`region_${i}`} />
            ))}
          </CustomContainer>
        </ContentSection>
        <ContentSection>
          <CustomContainer>
            <hr
              css={css`
                border-bottom: 1px solid ${styles.colors.blueLight};
                border-top: 0;
              `}
            />
            <Link to="/affiliates">
              <Text
                h2
                className="text-center"
                css={css`
                  color: ${styles.colors.black};
                  margin-bottom: ${styles.margin.mid};
                `}
              >
                View our List of{' '}
                <span
                  css={(theme) =>
                    css`
                      color: ${theme.headingColor};
                    `
                  }
                >
                  Affiliates
                </span>
              </Text>
            </Link>
          </CustomContainer>
        </ContentSection>
      </MainContentWrap>
    </Layout>
  )
}
