import React from "react";
import { css } from "@emotion/core";
import styles from "../constants/theme";

export default function H1({ children, ...rest }) {
  return (
    <h1
      css={theme => css`
        color: ${theme.headingColor};
        font-size: 144px;
        position: relative;
        padding-top: calc(40vh - ${styles.nav.height});
        margin-bottom: ${styles.margin.mid};
        ${styles.mq[1]} {
          font-size: 48px;
        }
      `}
      {...rest}
    >
      {children}
    </h1>
  );
}
